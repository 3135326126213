import Lottie from 'lottie-react';
import AnimationSearch from '@/icons/animations/Search.json'
import { animationSizes } from '@/constants'
import { useTranslation } from 'react-i18next';

export default function RafflePreEnd(props) {
	const { t } = useTranslation()

	return (
		<div className='boxIntro'>
			<div className='boxIntro_sticker'>
				<Lottie animationData={AnimationSearch} style={animationSizes['AnimationSearch']} />
			</div>
			<div className='boxIntro_title'>{ t('features.raffle.preEnd.title') }</div>
			<div className='boxIntro_text'>{ t('features.raffle.preEnd.text') }</div>
			<button className='btn btn--tt-none' onClick={props.openRaffle}>{ t('features.raffle.preEnd.btn') }</button>
		</div>
	)
}
