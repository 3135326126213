import { Player } from 'video-react'
import { useSelector } from 'react-redux'
import { getRaffle } from '../reducer';
import "video-react/styles/scss/video-react.scss";
import parse from 'html-react-parser'
import { bgColorsArray } from '@/constants';
import { useTranslation } from 'react-i18next';
import { raffleTypeForPay } from '@/constants'

export default function RaffleContent(props) {
	const raffle = useSelector(getRaffle)
	const { t } = useTranslation()

	return (
		<>
			{props.simple ?
				<div className='boxText'>
					<div className='boxText_body'>
						{parse(raffle.text)}
						<br/><br/>
						{ t('features.raffle.content.members') } <b>{raffle.members_count}</b><br/>
						{ t('features.raffle.content.winPlace') } <b>{raffle.winners}</b><br/>
						{ t('features.raffle.content.date') } <b>{raffle.date_end}</b>

						{raffle.image &&
							<div className='boxImage'>
								{raffle.image.slice(-4) === '.mp4' ?
									<Player src={raffle.image} />
								:
									<img src={raffle.image} alt='' />
								}
							</div>
						}
					</div>
				</div>
			:
				<>
					<div className='boxText'>
						<div className='boxText_body'>
							{parse(raffle.text)}
							{raffle.image &&
								<div className='boxImage'>
									{raffle.image.slice(-4) === '.mp4' ?
										<Player src={raffle.image} />
									:
										<img src={raffle.image} alt='' />
									}
								</div>
							}
						</div>
					</div>

					<div className='boxText'>
						<div className='boxText_header'>{ t('features.raffle.content.paramsTitle') }</div>
						<div className='boxText_body'>
							{raffle.type === raffleTypeForPay ?
								<>
									{ t('features.raffle.content.tickets') } <b>{raffle.tickets_count}</b><br/>
								</>
							:
								<>
									{ t('features.raffle.content.members') } <b>{raffle.members_count}</b><br/>
								</>
							}
							{ t('features.raffle.content.winPlace') } <b>{raffle.winners}</b><br/>
							{ t('features.raffle.content.date') } <b>{raffle.date_end}</b>
						</div>
					</div>

					<div className='boxTitle'>{ t('features.raffle.content.channels') }</div>
					<div className='boxList'>
						{raffle.channels.map((channel, index) =>
							<a href={channel.link} className='boxList_item' key={index}>
								{channel.photo ?
										<div className='boxList_item-icon'>
											<img src={channel.photo} alt='' />
										</div>
									:
										<div className='boxList_item-icon'>
											<span style={{backgroundColor: bgColorsArray[Math.floor(Math.random()*bgColorsArray.length)]}}>{channel.name.substring(0, 1)}</span>
										</div>
									}
								<div className='boxList_item-content'>
									<div className='boxList_item-title'>{channel.name}</div>
								</div>
								<div className='boxList_item-right'>
									<div className='btn btn--sm'>{ t('features.raffle.content.go') }</div>
								</div>
							</a>
						)}
					</div>
				</>
			}
		</>
	)
}
