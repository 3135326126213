import { useSelector } from 'react-redux'
import { getRaffle } from '../reducer';
import { getUser } from '@/modules/User/reducer';
import Lottie from 'lottie-react';
import AnimationWin from '@/icons/animations/Win.json'
import AnimationCup from '@/icons/animations/Cup.json'
import AnimationFailure from '@/icons/animations/Failure.json'
import AnimationConfetti from '@/icons/animations/Confetti.json'
import { useEffect, useState } from 'react';
import { animationSizes, bgColorsArray } from '@/constants'
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

export default function RaffleEnd(props) {
	const raffle = useSelector(getRaffle)
	const user = useSelector(getUser)
	const [step, setStep] = useState()
	const { t } = useTranslation()

	const steps = {
		win: {
			animation: {
				json: AnimationWin,
				style: animationSizes['AnimationWin'],
				loop: false
			},
			confetti: true
		},
		lose: {
			animation: {
				json: AnimationCup,
				style: animationSizes['AnimationCup'],
				loop: false
			},
			confetti: true
		},
		not: {
			animation: {
				json: AnimationFailure,
				style: animationSizes['AnimationFailure'],
				loop: false
			},
			confetti: false
		}
	}
	const i18nPath = 'features.raffle.end.'
	useEffect(() => {
		let temp
		if (raffle.tickets.length === 0) {
			temp = steps.not
			temp.title = raffle.winners === 1 ? i18nPath + 'not.titleOne' : i18nPath + 'not.title'
			temp.text = i18nPath + 'not.text'
			setStep(temp)
		} else if (user.ticket) {
			temp = steps.win
			temp.title = i18nPath + 'win.title'
			temp.text = i18nPath + 'win.text'
			temp.textTags = {b: <b />, a: <a />}
			temp.textValues = {
				place: user.ticket.place,
				raffle: raffle.title,
				ticket: user.ticket.ticket,
				linkAdmin: raffle.user.url,
				admin: raffle.user.name
			}
			setStep(temp)
		} else {
			temp = steps.lose
			temp.title = raffle.tickets.length === 1 ? i18nPath + 'lose.titleOne' : i18nPath + 'lose.title'
			temp.text = raffle.tickets.length === 1 ? i18nPath + 'lose.textOne' : i18nPath + 'lose.text'
			temp.textTags = {b: <b />}
			temp.textValues = {
				raffle: raffle.title,
				date: raffle.date_end
			}
			setStep(temp)
		}
	}, [])

	return (
		step &&
			<>
				<div className='boxIntro'>
					{step.confetti &&
						<div className='boxIntro_bgAnimation'>
							<Lottie animationData={AnimationConfetti} loop={false} />
						</div>
					}
					{step.animation &&
						<div className='boxIntro_sticker'>
							<Lottie animationData={step.animation.json} loop={step.animation.loop} style={step.animation.style} />
						</div>
					}
					<div className='boxIntro_title'>{ t(step.title) }</div>
					<div className='boxIntro_text'>
						<Trans i18nKey={step.text} components={step.textTags} values={step.textValues} />
					</div>
					{raffle.tickets.length === 0 ?
						<button className='btn btn--tt-none' onClick={props.openRaffle}>{ t('features.raffle.end.aboutRaffle') }</button>
					:
						<Link className='btn btn--tt-none' to='/process'>
							{raffle.tickets.length > 1 ? t('features.raffle.end.howRaffle') : t('features.raffle.end.howRaffleOne')}
						</Link>
					}
				</div>

				{raffle.tickets.length > 0 &&
					<>
						<div className='boxTitle'>
							{raffle.tickets.length > 1 ? t('features.raffle.end.winners') : t('features.raffle.end.winner')}
						</div>
						<div className='boxFolder'>
							{raffle.tickets.map((ticket) =>
								<a href={ticket.user.url} className='boxFolderItem' key={ticket.place}>
									<div className='boxFolderItem-img'>
										{ticket.user.photo ?
											<img src={ticket.user.photo} alt='' />
										:
											<span style={{backgroundColor: bgColorsArray[Math.floor(Math.random()*bgColorsArray.length)]}}>{ticket.user.name.substring(0, 1)}</span>
										}
									</div>
									<div className='boxFolderItem-info'>
										<div className='boxFolderItem-name'>{ticket.user.name}</div>
										<div className='boxFolderItem-ticket'>
											{ t('features.raffle.end.ticket') } <b>{ticket.ticket}</b>
											{ticket.replayed &&
												<i> { t('features.raffle.end.reWin') }</i>
											}
										</div>
									</div>
									<div className='boxFolderItem-count boxFolderItem-count--place'>
										<span>#{ticket.place}</span>
									</div>
								</a>
							)}
						</div>
					</>
				}
			</>
	)
}
