import { useSelector } from 'react-redux'
import { getUser } from '@/modules/User/reducer';
import { getRaffle } from '@/features/Raffle/reducer';
import { useState } from 'react';
import copy from 'copy-to-clipboard';
import MemberShareBtn from './ShareBtn';
import { useHapticFeedback } from '@vkruglikov/react-telegram-web-app';
import { useTranslation, Trans } from 'react-i18next';
import Alert from '@/components/Alert';

export default function MemberRef(props) {
	const raffle = useSelector(getRaffle)
	const user = useSelector(getUser)
	const [impactOccurred, notificationOccurred] = useHapticFeedback();
	const { t } = useTranslation()

	const [copied, setCopied] = useState(false);

	const handleCopy = () => {
		copy(user.ref_url)
		if (copied) return false;
		impactOccurred('medium')
		setCopied(true)
		setTimeout(() => {
			setCopied(false)
		}, 3000)
	}

	return (
		<>
			<div className='boxIntro'>
				<div className='boxIntro_title'>{ t('modules.member.ref.title') }</div>
				<div className='boxIntro_text'>
					<Trans i18nKey="modules.member.ref.text" components={{ br: <br /> }} values={{ count: t('modules.member.ref.friends.' + raffle.referral + '.count'), even: t('modules.member.ref.friends.' + raffle.referral + '.even') }} />

				</div>
				<button className='btn btn--tt-none' onClick={props.openRaffle}>{ t('modules.member.ref.btn') }</button>
			</div>
			<div className='boxText'>
				<div className='boxText_header'>{ t('modules.member.ref.link') }</div>
				<div className='boxText_body'>
					<div className='boxLink' onClick={handleCopy}>
						{!copied ? user.ref_url : t('modules.member.ref.copied')}
					</div>
					<div className='boxGrid'>
						<button className='btn btn--tt-none' onClick={handleCopy}>{!copied ? t('modules.member.ref.copy') : t('modules.member.ref.copied')}</button>
						<MemberShareBtn raffle={raffle.id} />
					</div>
				</div>
			</div>
			{raffle.referral && user.ref_url &&
				<>
					<div className='boxTitle'>{ t('modules.member.ref.stat.title') }</div>
					<div className='boxStat'>
						<div className='boxStatItem'>
							<b>{user.stat_user}</b>
							<span>{ t('modules.member.ref.stat.users') }</span>
						</div>
						<div className='boxStatItem'>
							<b>{user.stat_member}</b>
							<span>{ t('modules.member.ref.stat.members') }</span>
						</div>
					</div>
				</>
			}
			{copied && <Alert>{ t('modules.member.ref.alert') }</Alert>}
		</>
	)
}
